class PagesHelper {

  async boot(pageModuleName, pageAttributes) {
    // The include is critical to avoid loading the README.md file
    const modulePromise = import(
      /* webpackChunkName: "page__[request]" */
      /* webpackInclude: /\.js$/ */
      /* webpackMode: "lazy" */
      `bootable/${pageModuleName}`
    );
    return modulePromise.then((module) => {
      const pageClass = module.default;
      const page = new pageClass(pageAttributes);
      page.boot();
      return page;
    });
  }
}

export const pagesHelper = new PagesHelper();
export default pagesHelper;
