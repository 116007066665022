import ProgressBar from 'progressbar.js';
import html2canvas from 'html2canvas';
import IBAN from 'iban';

window.ProgressBar = ProgressBar
window.html2canvas = html2canvas
window.IBAN = IBAN

// html2canvas used to accept jquery selector and options hash, it is not the case anymore
window.html2canvasCompat = function($elem, { onrendered }){
  console.log("please do not use html2canvasCompat, use html2canvas with dom element and promise ")
  const element = $elem[0];
  html2canvas(element).then(onrendered)
}
