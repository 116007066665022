import {TOAST_ANIMATION_TIME, TOAST_CONTAINER_ANIMATION_TIME} from "./settings";

class ToastsCenter {
  constructor() {
    this.toastsStack = [];
    this.toastContainer = null;
  }

  init() {
    this.toastContainer = document.getElementById('toasts-container');
  }

  add(toast, toastId, displayTime) {
    const toastDisplayer = setTimeout(() => this.remove(toastId), displayTime)
    this.addToContainer(toastId, toast);
    this.addToStack(toastId, toastDisplayer)
  }

  remove(toastId) {
    const toastToRemove = this.toastsStack.find(toast => toast.id === toastId);
    const toastElement = document.getElementById(toastId);
    toastElement?.classList.add('fade-out');

    clearTimeout(toastToRemove.timer);
    setTimeout(() => {
      toastElement?.remove();
    }, TOAST_ANIMATION_TIME)

    this.toastsStack.filter(toast => toast.id === toastId);
  }

  addToContainer(toastId, toast) {
    this.toastContainer.prepend(toast);
    const {height} = document.getElementById(toastId).getBoundingClientRect();
    this._animateContainer(height)
  }

  addToStack(toastId, toastDisplayer) {
    this.toastsStack.push({
      id: toastId,
      timer: toastDisplayer,
    })
  }

  _animateContainer(height) {
    this.toastContainer.animate(
      [
        {transform: `translateY(-${height}px)`},
        {transform: 'translateY(0)'},
      ], {
        easing: 'ease-out',
        duration: TOAST_CONTAINER_ANIMATION_TIME,
      }
    );
  }
}

window.ToastsCenter = new ToastsCenter();