import 'core-js/stable';
import 'initializers';
import 'regenerator-runtime/runtime';
import pagesHelper from 'src/global/pages_helper';
import { renderComponent, renderComponents } from 'src/global/react_helper';
import { purposeAttributes } from 'src/global/test_helper';

/* eslint no-console:0 */

// // allows webpack to load assets from runtime ASSET_HOST
// // see https://webpack.js.org/guides/public-path/#on-the-fly
// import { asset_host } from "NodalviewEnvironment";
// __webpack_public_path__ = asset_host;

window.pagesHelper = pagesHelper;
window.renderComponent = renderComponent;
window.renderComponents = renderComponents;
window.purposeAttributes = purposeAttributes;