import Swal from 'sweetalert2';

var sweetAlertConfirmConfig = {
  animation: false,
  confirmButtonColor: '#85cbea',
  customClass: 'swal-nodalview',
};

(function ($) {
  var sweetAlertConfirm = function (event) {

    var swalDefaultOptions = {
      title: sweetAlertConfirmConfig.title || 'Are you sure?',
      icon: sweetAlertConfirmConfig.icon || 'warning',
      showCancelButton: sweetAlertConfirmConfig.showCancelButton || true,
      confirmButtonText: sweetAlertConfirmConfig.confirmButtonText || 'Ok',
      cancelButtonText: sweetAlertConfirmConfig.cancelButtonText || 'Cancel'
    };
    if (sweetAlertConfirmConfig.confirmButtonColor !== null) {
      swalDefaultOptions.confirmButtonColor = sweetAlertConfirmConfig.confirmButtonColor;
    }
    if (sweetAlertConfirmConfig.customClass !== null) {
      swalDefaultOptions.customClass = sweetAlertConfirmConfig.customClass;
    }

    var $linkToVerify = $(this);
    var swalOptions = swalDefaultOptions;
    var optionKeys = [
      'text',
      'showCancelButton',
      'confirmButtonColor',
      'cancelButtonColor',
      'confirmButtonText',
      'cancelButtonText',
      'html',
      'imageUrl',
      'allowOutsideClick',
      'customClass'
    ];

    function sweetAlertConfirmedCallback(result) {
      if (result.isDismissed) {
        return;
      }

      if ($linkToVerify.data().remote === true) {
        $.rails.handleRemote($linkToVerify);
      } else if ($linkToVerify.data().method !== undefined) {
        $.rails.handleMethod($linkToVerify);
      } else {
        if ($linkToVerify.attr('type') === 'submit') {
          var name = $linkToVerify.attr('name'),
            data = name ? {
              name: name,
              value: $linkToVerify.val()
            } : null;
          $linkToVerify.closest('form').data('ujs:submit-button', data);
          $linkToVerify.closest('form').submit();
        } else {
          $linkToVerify.data('swal-confirmed', true);
          event.target.click();
        }
      }
    }

    if ($linkToVerify.data('swal-confirmed')) {
      $linkToVerify.data('swal-confirmed', false);
      return;
    }

    $.each($linkToVerify.data(), function (key, val) {
      if ($.inArray(key, optionKeys) >= 0) {
        swalOptions[key] = val;
      }
    });

    if ($linkToVerify.attr('data-sweet-alert-icon')) {
      swalOptions['icon'] = $linkToVerify.attr('data-sweet-alert-icon');
    }

    var message = $linkToVerify.attr('data-sweet-alert-confirm');
    swalOptions['title'] = message;
    Swal.fire(swalOptions).then(sweetAlertConfirmedCallback);


    event.stopImmediatePropagation();
    event.preventDefault();
  };

  $(document).on('click touchstart', '[data-sweet-alert-confirm]', sweetAlertConfirm);


})(jQuery);


window.Swal = Swal;
window.swal = Swal.fire.bind(Swal);
window.closeSwal = Swal.close.bind(Swal);

