const TOAST_TYPES = {
  information: 'information',
  success: 'success',
  warning: 'warning',
  error: 'error',
}

const TOAST_ICONS = {
  information: 'info-sign',
  success: 'ok-sign',
  warning: 'alert',
  error: 'alert'
}

const TOAST_TIMER = 3500;
const TOAST_CONTAINER_ANIMATION_TIME = 500;
const TOAST_ANIMATION_TIME = 1000;

export { TOAST_TYPES, TOAST_ICONS, TOAST_TIMER, TOAST_ANIMATION_TIME, TOAST_CONTAINER_ANIMATION_TIME };