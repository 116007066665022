import uniqueId from 'lodash/uniqueId'
import { TOAST_TYPES, TOAST_ICONS, TOAST_TIMER } from './settings';
class Toast {
  constructor({
    type,
    title,
    message,
    icon,
    displayTime,
  }) {
    this.id = `toast-message-${uniqueId()}`;
    this.type = type || TOAST_TYPES.information;
    this.displayTime = displayTime || TOAST_TIMER;
    this.title = title;
    this.message = message;
    this.icon = icon || TOAST_ICONS[this.type];
    this.cloneNotification = null;
    this.build();
  }

  checkToastValidity() {
    if (!this.message) {
      console.error('You need to provide a message for your Toast message');
      return false;
    }
    return true;
  }

  build() {
    if (!this.checkToastValidity()) {
      return;
    }
    this._cloneNotification();
    this._setMetas();
    this._setMessage();
    this._setIcon();
    this._setTitle();
    this._setClose();
  }

  show() {
    ToastsCenter.add(this.cloneNotification, this.id, this.displayTime);
  }

  hide() {
    ToastsCenter.remove(this.id);
  }

  _cloneNotification() {
    const notificationTemplate = document.querySelector('template[id="toast"]')
    this.cloneNotification = notificationTemplate.content.cloneNode(true);
  }

  _setMetas() {
    this.cloneNotification.querySelector('.toast').setAttribute('type', this.type);
    this.cloneNotification.querySelector('.toast').setAttribute("id", this.id);
  }

  _setMessage() {
    this.cloneNotification.querySelector('.toast-message').innerHTML = this.message;
  }

  _setIcon() {
    this.cloneNotification.querySelector('.glyphicon').classList.add(`glyphicon-${this.icon}`);
  }

  _setTitle() {
    if(this.title) {
      this.cloneNotification.querySelector('.toast-title').innerText = this.title;
    } else {
      this.cloneNotification.querySelector('.toast-title').remove();
    }
  }

  _setClose() {
    this.cloneNotification.querySelector('.toast-close').addEventListener('click', this.hide.bind(this));
  }
}

window.Toast = Toast;