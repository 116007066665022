import dayjs from "dayjs";
import "dayjs/locale/en";
import "dayjs/locale/es";
import "dayjs/locale/fr";
import "dayjs/locale/it";
import "dayjs/locale/ja";
import "dayjs/locale/nl";
import "dayjs/locale/pt";
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isToday from "dayjs/plugin/isToday";
import isTomorrow from "dayjs/plugin/isTomorrow";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

const locale = document.documentElement.lang

dayjs.extend(isToday)
dayjs.extend(isTomorrow);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isSameOrBefore)
dayjs.extend(isSameOrAfter)
dayjs.locale(locale)