const setView = () => {
	$('.edit_panorama').submit();
	window.activekrpanowindow = "edited_pano";
	$('#edited_pano').focus();
	return swal({
		title: 'Default view has been set to the current position!',
		type: 'success',
		timer: 2000
	});
};

$(document).on("click", "[data-behavior~=set-panorama-view-position]", () => setView())