// eslint-disable-next-line camelcase
import { enable_data_test_attributes } from 'NodalviewEnvironment';

export function purposeAttributes(purpose) {
  if (enable_data_test_attributes) {
    return { 'data-test-purpose': purpose };
  } else {
    return {};
  }
}
