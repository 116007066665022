import {
  PASSWORD_MISSING_CASE,
  PASSWORD_MISSING_NUMBER_OR_SYMBOL,
  PASSWORD_TOO_SHORT,
} from '../constants';

export function ValidatePassword(password) {
  const errors = [];

  if (password.length < 8) errors.push(PASSWORD_TOO_SHORT);
  if (!/[A-Z]/.test(password)) errors.push(PASSWORD_MISSING_CASE);
  if (!/[a-z]/.test(password)) errors.push(PASSWORD_MISSING_CASE);
  if (!/[^a-zA-Z\s]/.test(password)) errors.push(PASSWORD_MISSING_NUMBER_OR_SYMBOL);

  return errors;
}
